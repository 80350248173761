export function authHeader() {
    // return authorization header with jwt token
    let token = window.$cookies.get('token');
    if (token) {
        return { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token };
    } else {
        return {};
    }
}
export function authHeaderUpload() {
    // return authorization header with jwt token
    let token = window.$cookies.get('token');
    if (token) {
        return { 'Content-Type': 'multipart/form-data' };
    } else {
        return {};
    }
}
