export const routerAgency = [
    {
        path: '/agency/list',
        name: 'Agency',
        meta: { authRequired: true, roleDefault: '', role: ['agency.view'] },
        component: () => import('@/views/pages/agency')
    },
    {
        path: '/agency/form',
        name: 'AgencyForm',
        meta: { authRequired: true, roleDefault: '', role: ['agency.register', 'agency.edit'] },
        component: () => import('@/views/pages/agency/form')
    },
    {
        path: '/agency/view',
        name: 'AgencyView',
        meta: { authRequired: true, roleDefault: '', role: ['agency.view'] },
        component: () => import('@/views/pages/agency/view')
    },
    {
        path: '/agency/form/support',
        name: 'AgencySupport',
        meta: { authRequired: true, roleDefault: '', role: ['agency.edit', 'agency.register'] },
        component: () => import('@/views/pages/agency/formSupport')
    },
    {
        path: '/agency/support/view',
        name: 'AgencySupportView',
        meta: { authRequired: true, roleDefault: '', role: ['agency.view'] },
        component: () => import('@/views/pages/agency/formSupportView')
    }
];
