import { agencyService } from '@/helpers/fakebackend/agency.service';

export const getters = {
    dataList(state) {
        console.log('getter', state);
        return state.dataList;
    }
};

export const actions = {
    // register the master
    registerAgency({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return agencyService.registerAgency(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // register the master
    updateAgency({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return agencyService.updateAgency(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // register the master
    registerAgencySupport({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return agencyService.registerAgencySupport(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // register the master
    updateAgencySupport({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return agencyService.updateAgencySupport(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    listAgency({ dispatch, commit }, query) {
        commit('listRequest', []);
        return agencyService.listAgency(query).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    listAgencySupport({ dispatch, commit }, id) {
        commit('listRequest', []);
        return agencyService.listAgencySupport(id).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    // list the master
    detailAgency({ dispatch, commit }, data) {
        commit('listRequest', []);
        return agencyService.detailAgency(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // list the master
    detailAgencySupport({ dispatch, commit }, data) {
        commit('listRequest', []);
        return agencyService.detailAgencySupport(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // list the master
    deleteAgency({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return agencyService.deleteAgency(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // list the master
    deleteAgencySupport({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return agencyService.deleteAgencySupport(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }
};

export const mutations = {
    registerRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    registerSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    registerFailure(state) {
        state.status = {};
        state.data = null;
    },
    listRequest(state, data) {
        state.status = false;
        state.dataList = data;
    },
    listSuccess(state, data) {
        state.status = true;
        // console.log('state State', state)
        state.dataList = data;
        //console.log('state State', state)
    },
    listFailure(state) {
        state.status = {};
        state.dataList = [];
    },
    deleteRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    deleteSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    deleteFailure(state) {
        state.status = {};
        state.data = null;
    }
};
