import { authHeader } from '@/helpers/fakebackend/auth-header';

export const prizeService = {
    registerPrize,
    registerAnswer,
    listPrize,
    detailPrize,
    deleteAnswer,
    deletePrize,
    updateFaqs,
    updateFaqsAnswerDefault,
    updateAnswer,
    updatePrize,
    registerPrizeReturn,
    deletePrizeReturn,
    updatePrizeReturn,
    listPrizeReturn,
    getPrizesAll
};

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    window.$cookies.remove('token');
}

function registerPrize(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/prizes`, requestOptions).then(handleResponse);
}
function registerPrizeReturn(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/return-prizes`, requestOptions).then(handleResponse);
}

function listPrizeReturn(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/return-prizes?` + query, requestOptions).then(handleResponse);
}
function registerAnswer(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/answers`, requestOptions).then(handleResponse);
}

function detailPrize(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/prizes/` + data, requestOptions).then(handleResponse);
}

function listPrize(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/prizes?` + query, requestOptions).then(handleResponse);
}

function deleteAnswer(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/answers/` + data.id, requestOptions).then(handleResponse);
}

function deletePrize(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/prizes/` + data.id, requestOptions).then(handleResponse);
}

function deletePrizeReturn(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/return-prizes/` + data.id, requestOptions).then(handleResponse);
}

function updateFaqs(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/` + data.id, requestOptions).then(handleResponse);
}
function updatePrizeReturn(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/return-prizes/` + data.id, requestOptions).then(handleResponse);
}
function updateFaqsAnswerDefault(data) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/${data.id}/answer-default/`, requestOptions).then(handleResponse);
}

function updateAnswer(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/faqs/answers/${data.id}`, requestOptions).then(handleResponse);
}

function updatePrize(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/prizes/${data.id}`, requestOptions).then(handleResponse);
}

function getPrizesAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/prizes/all`, requestOptions).then(handleResponse);
}

function refresh() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/auth/refresh`, requestOptions).then((response) => {
        return response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (data.code == 200 && data.data) {
                window.$cookies.set('token', data.data.access_token);
                location.reload(true);
            } else {
                logout();
                location.reload(true);
            }
        });
    });
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                if (data.message !== 'token.expired') {
                    // auto logout if 401 response returned from api
                    logout();
                    location.reload(true);
                } else {
                    refresh();
                    // location.reload(true);
                }
            }
            if (response.status === 422) {
                return data;
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
