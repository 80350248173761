import { authHeader } from '@/helpers/fakebackend/auth-header';

export const partnersService = {
    registerPartners,
    registerPartnersSupport,
    listPartners,
    listPartnersSupport,
    detailPartners,
    detailPartnersSupport,
    deletePartners,
    deletePartnersSupport,
    updatePartners,
    updatePartnersSupport,
    getPartnersSettingPhone
};

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    window.$cookies.remove('token');
}

function registerPartners(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners`, requestOptions).then(handleResponse);
}
function registerPartnersSupport(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/${data.partner_id}/person-in-charges`, requestOptions).then(handleResponse);
}

function detailPartners(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/` + data, requestOptions).then(handleResponse);
}
function detailPartnersSupport(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/${data.partner_id}/person-in-charges/${data.id}`, requestOptions).then(handleResponse);
}

function listPartners(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners?` + query, requestOptions).then(handleResponse);
}

function listPartnersSupport(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/` + id + `/person-in-charges`, requestOptions).then(handleResponse);
}

function deletePartners(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/` + data.id, requestOptions).then(handleResponse);
}

function deletePartnersSupport(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/${data.partner_id}/person-in-charges/${data.id}`, requestOptions).then(handleResponse);
}

function updatePartners(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/` + data.id, requestOptions).then(handleResponse);
}

function updatePartnersSupport(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/${data.partner_id}/person-in-charges/${data.id}`, requestOptions).then(handleResponse);
}

function getPartnersSettingPhone() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/partners/phone-setting/get-partners`, requestOptions).then(handleResponse);
}

function refresh() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/auth/refresh`, requestOptions).then((response) => {
        return response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (data.code == 200 && data.data) {
                window.$cookies.set('token', data.data.access_token);
                location.reload(true);
            } else {
                logout();
                location.reload(true);
            }
        });
    });
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                if (data.message !== 'token.expired') {
                    // auto logout if 401 response returned from api
                    logout();
                    location.reload(true);
                } else {
                    refresh();
                    // location.reload(true);
                }
            }
            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
            return data;
        }
        return data;
    });
}
