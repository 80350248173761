export const routerPartner = [
    {
        path: '/partners',
        name: 'Partners',
        meta: { authRequired: true, roleDefault: '', role: ['partner.view'] },
        component: () => import('@/views/pages/partner')
    },
    {
        path: '/partner/form',
        name: 'PartnerForm',
        meta: { authRequired: true, roleDefault: '', role: ['partner.register', 'partner.edit'] },
        component: () => import('@/views/pages/partner/form')
    },
    {
        path: '/partner/view',
        name: 'PartnerView',
        meta: { authRequired: true, roleDefault: '', role: ['partner.view'] },
        component: () => import('@/views/pages/partner/view')
    },
    {
        path: '/partner/form/support',
        name: 'PartnerSupport',
        meta: { authRequired: true, roleDefault: '', role: ['partner.register', 'partner.edit'] },
        component: () => import('@/views/pages/partner/formSupport')
    },
    {
        path: '/partner/support/view',
        name: 'PartnerSupportView',
        meta: { authRequired: true, roleDefault: '', role: ['partner.register', 'partner.edit'] },
        component: () => import('@/views/pages/partner/formSupportView')
    }
];
