import { clientService } from '@/helpers/fakebackend/client.service';

export const getters = {
    dataList(state) {
        console.log('getter', state);
        return state.dataList;
    }
};

export const actions = {
    // register the master
    registerClient({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return clientService.registerClient(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // register the master
    updateClient({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return clientService.updateClient(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // register the master
    registerClientSupport({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return clientService.registerClientSupport(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // register the master
    updateClientSupport({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return clientService.updateClientSupport(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    listClient({ dispatch, commit }, query) {
        commit('listRequest', []);
        return clientService.listClient(query).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    listClientSupport({ dispatch, commit }, id) {
        commit('listRequest', []);
        return clientService.listClientSupport(id).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    // list the master
    detailClient({ dispatch, commit }, data) {
        commit('listRequest', []);
        return clientService.detailClient(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // list the master
    detailClientSupport({ dispatch, commit }, data) {
        commit('listRequest', []);
        return clientService.detailClientSupport(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // list the master
    deleteClient({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return clientService.deleteClient(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // list the master
    deleteClientSupport({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return clientService.deleteClientSupport(data).then(
            (data) => {
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }
};

export const mutations = {
    registerRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    registerSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    registerFailure(state) {
        state.status = {};
        state.data = null;
    },
    listRequest(state, data) {
        state.status = false;
        state.dataList = data;
    },
    listSuccess(state, data) {
        state.status = true;
        // console.log('state State', state)
        state.dataList = data;
        //console.log('state State', state)
    },
    listFailure(state) {
        state.status = {};
        state.dataList = [];
    },
    deleteRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    deleteSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    deleteFailure(state) {
        state.status = {};
        state.data = null;
    }
};
