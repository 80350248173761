import { authHeader } from '@/helpers/fakebackend/auth-header';

export const clientService = {
    registerClient,
    registerClientSupport,
    listClient,
    listClientSupport,
    detailClient,
    detailClientSupport,
    deleteClient,
    deleteClientSupport,
    updateClient,
    updateClientSupport
};

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    window.$cookies.remove('token');
}

function registerClient(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients`, requestOptions).then(handleResponse);
}
function registerClientSupport(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients/${data.client_id}/person-in-charges`, requestOptions).then(handleResponse);
}

function detailClient(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients/` + data, requestOptions).then(handleResponse);
}
function detailClientSupport(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients/${data.client_id}/person-in-charges/${data.id}`, requestOptions).then(handleResponse);
}

function listClient(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients?` + query, requestOptions).then(handleResponse);
}

function listClientSupport(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients/` + id + `/person-in-charges`, requestOptions).then(handleResponse);
}

function deleteClient(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients/` + data.id, requestOptions).then(handleResponse);
}

function deleteClientSupport(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients/${data.client_id}/person-in-charges/${data.id}`, requestOptions).then(handleResponse);
}

function updateClient(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients/` + data.id, requestOptions).then(handleResponse);
}

function updateClientSupport(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/clients/${data.client_id}/person-in-charges/${data.id}`, requestOptions).then(handleResponse);
}
function refresh() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/auth/refresh`, requestOptions).then((response) => {
        return response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (data.code == 200 && data.data) {
                window.$cookies.set('token', data.data.access_token);
                location.reload(true);
            } else {
                logout();
                location.reload(true);
            }
        });
    });
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                if (data.message !== 'token.expired') {
                    // auto logout if 401 response returned from api
                    logout();
                    location.reload(true);
                } else {
                    refresh();
                    // location.reload(true);
                }
            }
            /**
             * comment để có thể bắt lỗi unique của các form khác.
             * const error = (data && data.message) || response.statusText;
             * return Promise.reject(error);
             */
        }
        return data;
    });
}
