export const routerAgency = [
    {
        path: '/agency/list',
        name: 'Agency',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['agency.view'],
            typeRouterForm: false,
            titleJP: '代理店一覧',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '代理店一覧',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/agency')
    },
    {
        path: '/agency/form',
        name: 'AgencyForm',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['agency.register', 'agency.edit'],
            typeRouterForm: true,
            titleJPAdd: '代理店新規登録',
            titleJPEdit: '代理店編集',
            pageNamesAdd: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '代理店新規登録',
                    active: true
                }
            ],
            pageNamesEdit: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '代理店一覧',
                    href: '/agency/list'
                },
                {
                    text: '代理店編集',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/agency/form')
    },
    {
        path: '/agency/view',
        name: 'AgencyView',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['agency.view'],
            typeRouterForm: false,
            titleJP: '代理店詳細',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '代理店一覧',
                    href: '/agency/list'
                },
                {
                    text: '代理店詳細',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/agency/view')
    },
    {
        path: '/agency/form/support',
        name: 'AgencySupport',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['agency.edit', 'agency.register'],
            typeRouterForm: true,
            titleJPAdd: '代理店担当者登録',
            titleJPEdit: '代理店担当者編集',
            pageNamesAdd: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '代理店一覧',
                    href: '/agency/list'
                },
                {
                    text: '代理店担当者登録',
                    active: true
                }
            ],
            pageNamesEdit: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '代理店一覧',
                    href: '/agency/list'
                },
                {
                    text: '代理店担当者編集',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/agency/formSupport')
    },
    {
        path: '/agency/support/view',
        name: 'AgencySupportView',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['agency.view'],
            typeRouterForm: false,
            titleJP: '代理店詳細',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: '代理店一覧',
                    href: '/agency/list'
                },
                {
                    text: '代理店詳細',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/agency/formSupportView')
    }
];
