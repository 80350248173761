export const routerCampaign = [
    {
        path: '/campaign/list',
        name: 'Campaign',
        meta: { authRequired: true, roleDefault: 'all', role: [] },
        component: () => import('@/views/pages/campaign')
    },
    {
        path: '/campaign/form',
        name: 'CampaignForm',
        meta: { authRequired: true, roleDefault: 'all', role: [] },
        component: () => import('@/views/pages/campaign/form/main.vue')
    },
    {
        path: '/campaign/edit',
        name: 'CampaignEdit',
        meta: { authRequired: true, roleDefault: 'all', role: [] },
        component: () => import('@/views/pages/campaign/edit/main.vue')
    },
    {
        path: '/campaign/form/view',
        name: 'CampaignView',
        meta: { authRequired: true, roleDefault: 'all', role: [] },
        component: () => import('@/views/pages/campaign/view/main.vue')
    }
];
