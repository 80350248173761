export const routerEmployee = [
    {
        path: '/employees',
        name: 'Employees',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['employee.view'],
            typeRouterForm: false,
            titleJP: 'パルディア社員一覧',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'パルディア社員一覧',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/employee')
    },
    {
        path: '/employee/form',
        name: 'EmployeeForm',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['employee.register', 'employee.edit'],
            typeRouterForm: true,
            titleJPAdd: 'パルディア社員新規登録',
            titleJPEdit: 'パルディア社員編集',
            pageNamesAdd: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'パルディア社員新規登録',
                    active: true
                }
            ],
            pageNamesEdit: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'パルディア社員一覧',
                    href: '/employees'
                },
                {
                    text: 'パルディア社員編集',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/employee/form')
    },
    {
        path: '/employee/view',
        name: 'EmployeeView',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['employee.view'],
            typeRouterForm: false,
            titleJP: 'パルディア社員詳細',
            pageNames: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'パルディア社員一覧',
                    href: '/employees'
                },
                {
                    text: 'パルディア社員詳細',
                    active: true
                }
            ]
        },
        component: () => import('@/views/pages/employee/view')
    }
];
