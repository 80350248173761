export const routerPrize = [
    {
        path: '/prize/list',
        name: 'Prize',
        meta: { authRequired: true, roleDefault: '', role: ['prize.view'] },
        component: () => import('@/views/pages/prize')
    },
    {
        path: '/prize/form',
        name: 'PrizeForm',
        meta: { authRequired: true, roleDefault: '', role: ['prize.register', 'prize.edit'] },
        component: () => import('@/views/pages/prize/form/main.vue')
    },
    {
        path: '/prize/form/view',
        name: 'PrizeFormView',
        meta: { authRequired: true, roleDefault: '', role: ['prize.view'] },
        component: () => import('@/views/pages/prize/view/main.vue')
    },
    {
        path: '/prize-return/list',
        name: 'PrizeReturn',
        meta: { authRequired: true, roleDefault: '', role: ['return_prize.view'] },
        component: () => import('@/views/pages/prize/return/index.vue')
    },

    {
        path: '/prize-return/form',
        name: 'PrizeReturnForm',
        meta: { authRequired: true, roleDefault: '', role: ['return_prize.register', 'return_prize.edit'] },
        component: () => import('@/views/pages/prize/return/form')
    }
];
