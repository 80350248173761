import { authHeader } from '@/helpers/fakebackend/auth-header';

export const settingPhoneService = {
    detailPhone,
    registerPhone,
    updatePhone,
    updateStepWorkflow,
    getListUserInRoom,
    getInfoChatWork,
    getTemplateConfig
};

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    window.$cookies.remove('token');
}

function detailPhone(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/phone-setting/${data}`, requestOptions).then(handleResponse);
}

function getTemplateConfig(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/phone-setting/config/${data.workflow_type}`, requestOptions).then(handleResponse);
}

function registerPhone(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/phone-setting`, requestOptions).then(handleResponse);
}

function updatePhone(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/phone-setting/${data.id}`, requestOptions).then(handleResponse);
}

function updateStepWorkflow(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/phone-setting/${data.id_phone_setting}/workflow/step/${data.id_step_parent}`, requestOptions).then(
        handleResponse
    );
}

function getListUserInRoom(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    if (data.room_id) {
        return fetch(process.env.VUE_APP_APIURL + `/chatwork/campaign/${data.campaign_id}/rooms/${data.room_id}/user`, requestOptions).then(
            handleResponse
        );
    } else {
        return fetch(process.env.VUE_APP_APIURL + `/chatwork/campaign/${data.campaign_id}/rooms`, requestOptions).then(handleResponse);
    }
}

function getInfoChatWork(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/chatwork/info`, requestOptions).then(handleResponse);
}

function refresh() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/ auth / refresh`, requestOptions).then((response) => {
        return response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (data.code == 200 && data.data) {
                window.$cookies.set('token', data.data.access_token);
                location.reload(true);
            } else {
                logout();
                location.reload(true);
            }
        });
    });
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                if (data.message !== 'token.expired') {
                    // auto logout if 401 response returned from api
                    logout();
                    location.reload(true);
                } else {
                    refresh();
                    // location.reload(true);
                }
            }
            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
            return data;
        }
        return data;
    });
}
