<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import appConfig from '@/app.config';
import 'vue2-datepicker/locale/ja';
import { authComputed, authFackMethods } from '@/state/helpers';

export default {
    name: 'app',
    page: {
        // All subcomponent titles will be injected into this template.
        titleTemplate(title) {
            title = typeof title === 'function' ? title(this.$store) : title;
            return title ? `${title} | ${appConfig.title}` : appConfig.title;
        }
    },
    methods: {
        ...authFackMethods
    },
    computed: {
        ...authComputed
    },
    created() {
        if (
            window.location.pathname !== '/login' &&
            window.location.pathname !== '/forgot-password' &&
            window.location.pathname !== '/reset-password'
        ) {
            let token = this.$cookies.get('token');
            if (token !== null) {
                this.loading = true;
                this.profile();
            }
        }
    },
    mounted() {}
};
</script>

<style lang="scss">
input[type='number'] {
    /* Ẩn mũi tên tăng/giảm (spinner) */
    -moz-appearance: textfield; /* Firefox */
}

/* Cho Opera và Webkit (Chrome, Safari) */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.pagination-old__paginator ul li.active {
    color: #ff3d60;
}

.border-right-1 {
    border-right: 1px solid;
    padding-right: 5px;
    margin-right: 5px;
}

.form-control.is-invalid {
    background-image: none !important;
}

.toast-body {
    display: none !important;
}

.box-date {
    position: relative;

    .mx-datepicker {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.swal2-confirm {
    order: 2;
}

.swal2-cancel {
    order: 1;
}

.form--sroll {
    display: block;
    height: calc(100vh - 150px - 60px);
    margin-bottom: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    outline: none;
}

.form--sroll1 .tab-content {
    height: calc(100vh - 150px - 125px - 60px - 30px);
    margin-bottom: 0px;
    overflow-x: hidden;
}

.multiselect__select {
    z-index: 9;
}

.is-invalid .multiselect__tags {
    border-color: #ff3d60 !important;
}

.is-invalid .mx-input {
    border-color: #ff3d60;
}

.multiselect__option--highlight,
.multiselect__option--highlight:after {
    background: #5664d2 !important;
    color: #fff !important;
}

.multiselect__option--selected {
    background: #5664d2 !important;
    color: #fff !important;
}

.multiselect__option--selected span {
    color: #fff !important;
}

.pagination__items {
    font-size: 16px;
}

.swal2-cancel {
    padding: 5px 12px !important;
}

.box-date .mx-datepicker input {
    cursor: pointer;
}

.filter {
    position: absolute;
    right: 0px;
    top: 4px;
}

.sort {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    i {
        height: 2px;
        cursor: pointer;

        &:first-child {
            color: lightgray;
        }
    }

    &.asc {
        i {
            &:first-child {
                color: #000;
            }

            &:last-child {
                color: lightgray;
            }
        }
    }
}

#modal-agency .modal-dialog,
#modal-client .modal-dialog,
#modal-partner .modal-dialog {
    max-width: 600px;
}

.hash span:before {
    content: '#';
    display: inline;
}

.box-45 span:last-child i {
    display: none;
}

.box-se {
    option {
        cursor: help;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px; /* Điều chỉnh chiều rộng tối đa ở đây */
    }
}

.mce-content-body p {
    margin: 0 !important;
}

.tox-statusbar__branding,
.tox .tox-statusbar {
    display: none !important;
}

@media (min-width: 1200px) {
    #box3 .modal-xl {
        max-width: 1366px;
    }
    //#box3 .tab-content{
    //    height: calc(100% - 300px);
    //    overflow-y: scroll;
    //}
}

.style1 {
    padding: 0 30px;
}

.style1 pre {
    border: 1px solid #ddd;
    padding: 20px;
}

.box6 i:hover {
    cursor: pointer;
}

.bg-color1 {
    background: #ccc0d9;
    min-width: 150px;
}

.bg-color2 {
    background: #fde9d9;
}

.bg-color3 {
    background: #dbe5f1;
}

.bg-color4 {
    background: #f2dbdb;
}

.bg-color5 {
    background: #cfc;
}

.bg-color6 {
    background: #cff;
}

.bg-color7 {
    background: #c2d69b;
}

.bg-color8 {
    background: #eaf1dd;
}

input[type='checkbox']:hover {
    cursor: pointer;
}

.check-text-danger .text-danger {
    opacity: 0;
}

.check-text-danger textarea.form-control {
    min-width: 480px;
}
</style>
