import { mapState, mapGetters, mapActions } from 'vuex';

export const authComputed = {
    ...mapState('authfack', {
        currentUser: (state) => state.user
    }),
    ...mapGetters('authfack', ['loggedIn'])
};

export const masterComputed = {
    ...mapState('master', {
        dataList: (state) => state.dataList
    })
};

export const layoutComputed = {
    ...mapState('layout', {
        layoutType: (state) => state.layoutType,
        leftSidebarType: (state) => state.leftSidebarType,
        layoutWidth: (state) => state.layoutWidth,
        topbar: (state) => state.topbar,
        loader: (state) => state.loader
    })
};

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword']);

export const layoutMethods = mapActions('layout', [
    'changeLayoutType',
    'changeLayoutWidth',
    'changeLeftSidebarType',
    'changeTopbar',
    'changeLoaderValue'
]);

export const authFackMethods = mapActions('authfack', ['refresh', 'login', 'registeruser', 'logout', 'refreshtoken', 'forgot', 'resetPass']);

export const masterMethods = mapActions('master', [
    'setDefaultCampaign',
    'postDocumentFAQPreview',
    'postDocumentFAQ',
    'deleteDocumentFAQ',
    'updateDocumentFAQ',
    'getDocumentFAQ',
    'getDefaultRule',
    'createMasterRuleDetail1',
    'getDetailDocuments',
    'getAllDocuments',
    'updateMasterRuleDetail1',
    'detailMasterRule1',
    'getAllMasterRule',
    'deleteMasterRule2',
    'createMasterRule1',
    'deleteMasterRule1',
    'updateMasterRule1',
    'updateMasterRule',
    'listMasterRule1',
    'registerMasterRule',
    'updateRule2',
    'deleteRule2',
    'createRule2',
    'listRule',
    'documentPreview',
    'templateDelete',
    'templateCreate',
    'templateUpdate',
    'removeDraf',
    'saveDraf',
    'templateGetDraf',
    'templateGetDetail',
    'templateList',
    'deleteMasterHolidayYear',
    'updateHolidayYear',
    'listHolidayYear',
    'oneHolidayYear',
    'registerHolidayYear',
    'deleteMasterHoliday',
    'updateHoliday',
    'listHoliday',
    'registerHoliday',
    'histories',
    'findCompany',
    'searchAddress',
    'registerPermission',
    'dataPermission',
    'listPermission',
    'listMaster',
    'registerMaster',
    'deleteMaster',
    'updateMaster',
    'masterCampaigns',
    'getListScheduleFile',
    'getDetailScheduleFile',
    'createScheduleFile',
    'updateScheduleFile',
    'deleteScheduleFile',
    'partnerRequestSheet',
    'getPartnerRequestSheet',
    'updatePartnerRequestSheet',
    'getMasterOrderScope'
]);

export const employeeMethods = mapActions('employee', [
    'listEmployeePartTime',
    'registerEmployeePartTime',
    'registerEmployee',
    'detailEmployeePartTime',
    'updateEmployeePartTime',
    'updateEmployee',
    'listEmployee',
    'deleteEmployee',
    'detailEmployee',
    'deleteEmployeePartTime'
]);

export const partnersMethods = mapActions('partners', [
    'registerPartners',
    'updatePartners',
    'listPartners',
    'deletePartners',
    'detailPartners',
    'listPartnersSupport',
    'registerPartnersSupport',
    'updatePartnersSupport',
    'detailPartnersSupport',
    'deletePartnersSupport',
    'getPartnersSettingPhone'
]);

export const agencyMethods = mapActions('agency', [
    'registerAgency',
    'updateAgency',
    'listAgency',
    'deleteAgency',
    'detailAgencySuport',
    'listAgencySupport',
    'registerAgencySupport',
    'updateAgencySupport',
    'deleteAgencySupport',
    'detailAgency',
    'detailAgencySupport'
]);

export const clientMethods = mapActions('client', [
    'registerClient',
    'updateClient',
    'listClient',
    'deleteClient',
    'detailClientSupport',
    'listClientSupport',
    'registerClientSupport',
    'updateClientSupport',
    'deleteClientSupport',
    'detailClient'
]);

export const campaignMethods = mapActions('campaign', [
    'historyDocuments',
    'postDocuments',
    'copyCampaign',
    'uploadFile',
    'getDocuments',
    'triggerDocuments',
    'deleteBookmark',
    'getBookmark',
    'addBookmark',
    'approveCampaign',
    'unLockCampaign',
    'lockCampaign',
    'registerCampaign',
    'updateCampaign',
    'listCampaign',
    'deleteCampaign',
    'detailCampaign',
    'getDataDocuments',
    'getRuleSavePayLoad',
    'postRuleSavePayLoad',
    'setBookmarkCampaign',
    'unsetBookmarkCampaign'
]);

export const faqMethods = mapActions('faq', [
    'registerFaq',
    'updateFaqs',
    'updateFaqsAnswerDefault',
    'listFaqs',
    'deleteFaq',
    'detailFaq',
    'listPartnersSupport',
    'registerAnswer',
    'updateAnswer',
    'detailPartnersSupport',
    'deleteAnswer'
]);

export const prizeMethods = mapActions('prize', [
    'deletePrizeReturn',
    'listPrizeReturn',
    'registerPrizeReturn',
    'updatePrizeReturn',
    'registerPrize',
    'updatePrize',
    'updateFaqsAnswerDefault',
    'listPrize',
    'deletePrize',
    'detailPrize',
    'registerAnswer',
    'updateAnswer',
    'detailPartnersSupport',
    'deleteAnswer',
    'getPrizesAll'
]);

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear']);

export const dashboardMethods = mapActions('dashboard', ['listCountEmployeeDashboard']);

export const settingPhoneMethods = mapActions('settingPhone', [
    'detailPhone',
    'registerPhone',
    'updatePhone',
    'updateStepWorkflow',
    'refresh',
    'getListUserInRoom',
    'getInfoChatWork',
    'getTemplateConfig'
]);

export const manualService = mapActions('manual', ['detailManual', 'updateManual']);

export const listPaging = [10, 30, 50];

export const dataMaster = [
    {
        name: '代理店',
        key: 'agency',
        rowspan: 1,
        childMain: '種別',
        keyMain: 'type',
        child: []
    },
    {
        name: 'パルディア社員',
        key: 'employee',
        rowspan: 1,
        childMain: '権限・チーム関連',
        keyMain: 'department',
        child: [
            // {
            //   name: "チーム",
            //   key: "team"
            // },
            // {
            //   name: "グループ",
            //   key: "gr"
            // }
        ]
    },
    // {
    //   name: "外部パートナー",
    //   key: "foreign_partner",
    //   rowspan: 1,
    //   childMain: "種別",
    //   keyMain: "type",
    //   child: [
    //   ]
    // },
    {
        name: 'FAQ',
        key: 'faq_master',
        rowspan: 1,
        childMain: 'FAQシート項目',
        keyMain: 'sheets',
        child: []
    },
    {
        name: 'キャンペーン',
        key: 'campaign',
        rowspan: 36,
        childMain: '',
        keyMain: 'status',
        child: [
            {
                name: '対象商品カテゴリ',
                key: 'product_target'
            },
            {
                name: '当選数',
                key: 'number_prizes'
            },
            // {
            //   name: "応募条件",
            //   key: "conditions_applying_admission"
            // },
            {
                name: 'SNS応募条件',
                key: 'conditions_applying_sns'
            },
            // {
            //   name: "対象商品含む",
            //   key: "including_target_products"
            // },
            // {
            //   name: "合計金額",
            //   key: "total_amount"
            // },
            {
                name: 'タレント・キャラクター',
                key: 'talent_character'
            },
            {
                name: '告知方法',
                key: 'announcement_method'
            },
            // {
            //   name: "オープンキャンペーン",
            //   key: "open_campaign"
            // },
            // {
            //   name: "クローズドキャンペーン",
            //   key: "close_campaign"
            // },
            // {
            //   name: "オープン/クローズド同時実施",
            //   key: "close_open_campaign"
            // },
            // {
            //   name: "抽選",
            //   key: "draw_lots"
            // },
            // {
            //   name: "もれなく",
            //   key: "without_exception"
            // },
            // {
            //   name: "チーム",
            //   key: "team"
            // },
            {
                name: 'エンドクライエントカテゴリ',
                key: 'category_customer'
            },
            {
                name: 'メーカー',
                key: 'maker'
            },
            {
                name: '流通',
                key: 'distribution'
            },
            {
                name: '食品',
                key: 'food'
            },
            {
                name: '食料',
                key: 'drink'
            },
            {
                name: '医薬品',
                key: 'pharmaceuticals'
            },
            {
                name: '化粧品',
                key: 'cosmetics'
            },
            {
                name: 'トイレタリー・日用品',
                key: 'toiletries_daily_necessities'
            },
            {
                name: '家電製品',
                key: 'home_appliances'
            },
            {
                name: 'AV・コンピューター及び周辺機器',
                key: 'AVComputers_and_peripherals'
            },
            {
                name: '通信サービス',
                key: 'communication_service'
            },
            {
                name: '衣料･ファッション･装飾品',
                key: 'clothing_fashion_and_accessories'
            },
            {
                name: 'おもちゃ',
                key: 'toy'
            },
            {
                name: '出版･趣味･娯楽品',
                key: 'publishing_hobbies_entertainment_items'
            },
            {
                name: '家庭用品',
                key: 'household_goods'
            },
            {
                name: 'ペット用品',
                key: 'pet_supplies'
            },
            {
                name: '案件内容',
                key: 'project_details'
            },
            {
                name: 'ツール',
                key: 'tool'
            },
            {
                name: '購買条件',
                key: 'purchase_conditions_1'
            },
            // {
            //   name: "購買条件２",
            //   key: "purchase_conditions_2"
            // },
            {
                name: '対応指示',
                key: 'prize_less_status'
            },
            {
                name: 'お問い合わせ',
                key: 'inquiry'
            },
            {
                name: '種別',
                key: 'type'
            },
            {
                name: '賞品カテゴリ',
                key: 'gift_category'
            },
            {
                name: '賞品あまりステータス',
                key: 'prize.remain_status'
            },
            {
                name: 'デジタルギフト',
                key: 'prize.digital_gift'
            }
        ]
    }
];

export const keyMaster = {
    'employee.team': 'employee.team',
    'employee.department': 'employee.department',
    'foreign_partner.type': 'foreign_partner.type',
    'faq_master.category': 'faq_master.category',
    'faq_master.sheets': 'faq_master.sheets',
    'campaign.prize_less_status': 'campaign.prize_less_status',
    'campaign.type': 'campaign.type',
    'campaign.status': 'campaign.status',
    'agency.type': 'agency.type',
    'campaign.conditions_applying_admission': 'campaign.conditions_applying_admission',
    'campaign.project_details': 'campaign.project_details',
    'campaign.conditions_applying_sns': 'campaign.conditions_applying_sns',
    'campaign.product_target': 'campaign.product_target',
    'campaign.purchase_conditions_1': 'campaign.purchase_conditions_1',
    'campaign.purchase_conditions_2': 'campaign.purchase_conditions_2',
    'campaign.tool': 'campaign.tool',
    'campaign.information_ip': 'campaign.information_ip'
};

export const nameMaster = {
    'employee.team': 'チームマスタ',
    'employee.group': 'グループマスタ'
};
//管理者 quan ly  administrator
//営業 kinh doanh sale
//ディレクション huong dan instructor
//parter
//part_time
export function checkPermission(role) {
    const user = JSON.parse(localStorage.getItem('user'));
    let roleUser = user.role;
    let permission = roleUser.permissions;
    let index = permission.findIndex((x) => {
        return x.name === role;
    });
    if (index >= 0) {
        return true;
    } else {
        return false;
    }
}

export function checkPermissionName(listRole) {
    const user = JSON.parse(localStorage.getItem('user'));
    let role = user.role ? user.role.name : '';
    if (!role) {
        role = window.$cookies.get('role');
    }
    // console.log(role)
    if (listRole.includes(role)) {
        return true;
    } else {
        return false;
    }
}

export function formattedPostalCode(data) {
    if (!data) return '';
    return data.replace(/(\d{3})(\d{4})/, '$1-$2');
}

export function isValidDate(dateString) {
    // Kiểm tra định dạng ngày tháng năm (DD/MM/YYYY)
    const dateRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;

    if (!dateRegex.test(dateString)) {
        // Nếu không đúng định dạng, trả về false
        return false;
    }

    // Chuyển đổi chuỗi thành đối tượng Date
    const parts = dateString.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    // Tạo đối tượng Date và kiểm tra ngày có tồn tại không
    const dateObject = new Date(year, month - 1, day); // month - 1 vì tháng trong JavaScript bắt đầu từ 0

    // Kiểm tra xem ngày có tồn tại trong tháng không
    if (dateObject.getFullYear() === year && dateObject.getMonth() === month - 1 && dateObject.getDate() === day) {
        // Kiểm tra xem ngày có hợp lệ trong tháng không
        const lastDayOfMonth = new Date(year, month, 0).getDate();
        return day >= 1 && day <= lastDayOfMonth;
    }

    return false;
}

export function mapDataCampaigns(
    formData,
    configKeyword,
    listItemPersons,
    listDataPrize,
    target_product_lists_data,
    purchase_conditions_data,
    contact_informations_data,
    listCategoriesSub,
    subDataCustomerTeamPic
) {
    let objectData = JSON.parse(JSON.stringify(formData));
    if (objectData.associated_campaign) {
        objectData.associated_campaign = {
            id: objectData.associated_campaign?.id ?? null
        };
    }
    objectData.keywords = configKeyword.arrayTag;
    let target_product_lists = [];
    objectData.target_product_lists.forEach((item) => {
        target_product_lists.push({
            master_id: item,
            note: {
                detail: target_product_lists_data[item]
            }
        });
    });
    let purchase_conditions = [];
    objectData.purchase_conditions.forEach((item) => {
        purchase_conditions.push({
            master_id: item,
            note: {
                detail: purchase_conditions_data[item]
            }
        });
    });
    let contact_informations = [];
    // item == 3 không gửi phone number khi đang đang lấy từ phone setting
    objectData.contact_informations.forEach((item) => {
        contact_informations.push({
            type_id: item,
            extension: {
                content: item !== 3 ? (contact_informations_data ? contact_informations_data[item] : '') : ''
            }
        });
    });
    let part_time_employees = [];
    objectData.part_time_employees.forEach((item) => {
        if (item.id) part_time_employees.push(item.id);
    });
    let responsible_sub_persons = [];
    listItemPersons.forEach((x, idx) => {
        if (x.id) {
            responsible_sub_persons.push({
                user_id: x.id,
                sub_person_position: idx + 1
            });
        }
    });
    objectData.target_product_lists = target_product_lists;
    objectData.purchase_conditions = purchase_conditions;
    objectData.contact_informations = contact_informations;
    objectData.responsible_sub_persons = responsible_sub_persons;
    objectData.agencies.forEach((x) => {
        if (x.id) x.agency_id = x.id;
        delete x.id;
        delete x.name;
        delete x.agency_pic_name;
        delete x.agency_pic_department;
        delete x.agency_pic_email;
        delete x.kana_name;
        delete x.pivot;
        delete x.campaign_agency_id;
    });
    objectData.clients.forEach((x) => {
        if (x.id) x.client_id = x.id;
        delete x.id;
        delete x.name;
        delete x.client_pic_name;
        delete x.client_pic_department;
        delete x.client_pic_email;
        delete x.kana_name;
        delete x.pivot;
        delete x.nameType;
    });

    objectData.prizes = listDataPrize;
    objectData.part_time_employees = part_time_employees;
    objectData.categories = JSON.parse(JSON.stringify(listCategoriesSub));
    objectData.categories.forEach((item) => {
        item.hashtag = item?.configHashTag?.arrayTag ?? [];
        delete item.listCondition1;
        delete item.listCondition2;
    });
    delete objectData.updated_by;
    if (objectData.campaign_start_date_y && objectData.campaign_start_date_m && objectData.campaign_start_date_d) {
        objectData.campaign_start_date =
            objectData.campaign_start_date_y + '-' + objectData.campaign_start_date_m + '-' + objectData.campaign_start_date_d;
    }
    if (objectData.campaign_end_date_y && objectData.campaign_end_date_m && objectData.campaign_end_date_d) {
        objectData.campaign_end_date = objectData.campaign_end_date_y + '-' + objectData.campaign_end_date_m + '-' + objectData.campaign_end_date_d;
    }
    if (objectData.secretariat_start_date_y && objectData.secretariat_start_date_m && objectData.secretariat_start_date_d) {
        objectData.secretariat_start_date =
            objectData.secretariat_start_date_y + '-' + objectData.secretariat_start_date_m + '-' + objectData.secretariat_start_date_d;
    }
    if (objectData.secretariat_end_date_y && objectData.secretariat_end_date_m && objectData.secretariat_end_date_d) {
        objectData.secretariat_end_date =
            objectData.secretariat_end_date_y + '-' + objectData.secretariat_end_date_m + '-' + objectData.secretariat_end_date_d;
    }
    if (objectData.receipt_expiration_date_y && objectData.receipt_expiration_date_m && objectData.receipt_expiration_date_d) {
        objectData.receipt_expiration_date =
            objectData.receipt_expiration_date_y + '-' + objectData.receipt_expiration_date_m + '-' + objectData.receipt_expiration_date_d;
    }

    objectData.customer_sub_person = subDataCustomerTeamPic[0] !== null ? subDataCustomerTeamPic.map((item) => item.id) : [];

    return objectData;
}

export function mapDataCampaignsCopy(
    formData,
    configKeyword,
    listItemPersons,
    listDataPrize,
    target_product_lists_data,
    purchase_conditions_data,
    contact_informations_data,
    listCategoriesSub,
    subDataCustomerTeamPic
) {
    let objectData = JSON.parse(JSON.stringify(formData));
    if (objectData.associated_campaign) {
        objectData.associated_campaign = {
            id: objectData.associated_campaign?.id ?? null
        };
    }
    objectData.keywords = configKeyword.arrayTag;
    let target_product_lists = [];
    objectData.target_product_lists.forEach((item) => {
        target_product_lists.push({
            master_id: item,
            note: {
                detail: target_product_lists_data ? target_product_lists_data[item] : ''
            }
        });
    });
    let purchase_conditions = [];
    objectData.purchase_conditions.forEach((item) => {
        purchase_conditions.push({
            master_id: item,
            note: {
                detail: purchase_conditions_data ? purchase_conditions_data[item] : ''
            }
        });
    });
    let contact_informations = [];
    objectData.contact_informations.forEach((item) => {
        contact_informations.push({
            type_id: item,
            extension: {
                content: contact_informations_data ? contact_informations_data[item] : ''
            }
        });
    });
    let part_time_employees = [];
    objectData.part_time_employees.forEach((item) => {
        if (item.id) part_time_employees.push(item.id);
    });
    let responsible_sub_persons = [];
    listItemPersons.forEach((x, idx) => {
        if (x.id) {
            responsible_sub_persons.push({
                user_id: x.id,
                sub_person_position: idx + 1
            });
        }
    });
    objectData.target_product_lists = target_product_lists;
    objectData.purchase_conditions = purchase_conditions;
    objectData.contact_informations = contact_informations;
    objectData.responsible_sub_persons = responsible_sub_persons;
    objectData.agencies.forEach((x) => {
        if (x.id) x.agency_id = x.id;
        delete x.id;
        delete x.name;
        delete x.agency_pic_name;
        delete x.agency_pic_department;
        delete x.agency_pic_email;
        delete x.kana_name;
        delete x.pivot;
        delete x.campaign_agency_id;
    });
    objectData.clients.forEach((x) => {
        if (x.id) x.client_id = x.id;
        delete x.id;
        delete x.name;
        delete x.client_pic_name;
        delete x.client_pic_department;
        delete x.client_pic_email;
        delete x.kana_name;
        delete x.pivot;
        delete x.nameType;
    });

    objectData.prizes = listDataPrize;
    objectData.part_time_employees = part_time_employees;
    objectData.categories = JSON.parse(JSON.stringify(listCategoriesSub));
    objectData.categories.forEach((item) => {
        item.hashtag = item?.configHashTag?.arrayTag ?? [];
        delete item.listCondition1;
        delete item.listCondition2;
    });
    delete objectData.updated_by;
    if (objectData.campaign_start_date_y && objectData.campaign_start_date_m && objectData.campaign_start_date_d) {
        objectData.campaign_start_date =
            objectData.campaign_start_date_y + '-' + objectData.campaign_start_date_m + '-' + objectData.campaign_start_date_d;
    }
    if (objectData.campaign_end_date_y && objectData.campaign_end_date_m && objectData.campaign_end_date_d) {
        objectData.campaign_end_date = objectData.campaign_end_date_y + '-' + objectData.campaign_end_date_m + '-' + objectData.campaign_end_date_d;
    }
    if (objectData.secretariat_start_date_y && objectData.secretariat_start_date_m && objectData.secretariat_start_date_d) {
        objectData.secretariat_start_date =
            objectData.secretariat_start_date_y + '-' + objectData.secretariat_start_date_m + '-' + objectData.secretariat_start_date_d;
    }
    if (objectData.secretariat_end_date_y && objectData.secretariat_end_date_m && objectData.secretariat_end_date_d) {
        objectData.secretariat_end_date =
            objectData.secretariat_end_date_y + '-' + objectData.secretariat_end_date_m + '-' + objectData.secretariat_end_date_d;
    }
    if (objectData.receipt_expiration_date_y && objectData.receipt_expiration_date_m && objectData.receipt_expiration_date_d) {
        objectData.receipt_expiration_date =
            objectData.receipt_expiration_date_y + '-' + objectData.receipt_expiration_date_m + '-' + objectData.receipt_expiration_date_d;
    }

    objectData.campaign_master_order_scopes = objectData.campaign_master_order_scopes.map((x) => {
        return {
            large_item: x.large_item.id,
            medium_items: x.medium_items.map((y) => {
                return {
                    medium_item: y.medium_item.id,
                    small_items: y.small_items.map((z) => {
                        return {
                            small_item: z.small_item.id,
                            detail_items: z.detail_items.map((t) => t.id)
                        };
                    })
                };
            })
        };
    });

    objectData.campaign_master_order_scope_partner = objectData.campaign_master_order_scope_partner.map((itemPartner) => {
        return {
            master_order_scope_id: itemPartner?.master_order_scope?.id ?? null,
            tool_id: itemPartner?.tool?.id ?? null,
            partner_id: itemPartner?.partner?.id ?? null
        };
    });
    objectData.customer_sub_person = subDataCustomerTeamPic[0] !== null ? subDataCustomerTeamPic.map((item) => item.id) : [];

    handleCheckConditionTel(objectData);
    return objectData;
}

function handleCheckConditionTel(objectData) {
    objectData.has_tel_phone_setting = false;
    if (objectData.campaign_master_order_scopes && objectData.campaign_master_order_scopes.length > 0) {
        let getDetails = [];
        objectData.campaign_master_order_scopes.forEach((largeItem) => {
            largeItem.medium_items.forEach((mediumItem) => {
                mediumItem.small_items.forEach((smallItem) => {
                    getDetails.push(`${smallItem.small_item}`);
                });
            });
        });
        // 2 là id của điều kiện "電話" (TEL)
        objectData.has_tel_phone_setting = getDetails.includes('2');
    }
}
