export const routerClient = [
    {
        path: '/client/list',
        name: 'Client',
        meta: { authRequired: true, roleDefault: '', role: ['client.view'] },
        component: () => import('@/views/pages/client')
    },
    {
        path: '/client/form',
        name: 'ClientForm',
        meta: { authRequired: true, roleDefault: '', role: ['client.register', 'client.edit'] },
        component: () => import('@/views/pages/client/form')
    },
    {
        path: '/client/form/view',
        name: 'ClientView',
        meta: { authRequired: true, roleDefault: '', role: ['client.view'] },
        component: () => import('@/views/pages/client/view')
    },
    {
        path: '/client/form/support',
        name: 'ClientFormView',
        meta: { authRequired: true, roleDefault: '', role: ['client.register', 'client.edit'] },
        component: () => import('@/views/pages/client/formSupport')
    },
    {
        path: '/client/form/support/view',
        name: 'ClientFormSupportView',
        meta: { authRequired: true, roleDefault: '', role: ['client.view'] },
        component: () => import('@/views/pages/client/formSupportView')
    }
];
