import { authHeader } from '@/helpers/fakebackend/auth-header';

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    window.$cookies.remove('token');
}

function GET_COUNT_EMPLOYEE_DASHBOARD(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.VUE_APP_APIURL}/dashboard/count-employee-campaign?${query}`, requestOptions).then(handleResponse);
}
function refresh() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/auth/refresh`, requestOptions).then((response) => {
        return response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (data.code == 200 && data.data) {
                window.$cookies.set('token', data.data.access_token);
                location.reload(true);
            } else {
                logout();
                location.reload(true);
            }
        });
    });
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                if (data.message !== 'token.expired') {
                    // auto logout if 401 response returned from api
                    logout();
                    location.reload(true);
                } else {
                    refresh();
                    // location.reload(true);
                }
            }
            /**
             * comment để có thể bắt lỗi unique của các form khác.
             * const error = (data && data.message) || response.statusText;
             * return Promise.reject(error);
             */
        }
        return data;
    });
}

export const dashboardService = {
    GET_COUNT_EMPLOYEE_DASHBOARD
};
