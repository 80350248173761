export const routerFaq = [
    {
        path: '/faqs',
        name: 'FAQs',
        meta: { authRequired: true, roleDefault: '', role: ['faq.view'] },
        component: () => import('@/views/pages/faqs')
    },
    {
        path: '/faq/form',
        name: 'FAQsForm',
        meta: { authRequired: true, roleDefault: '', role: ['faq.register', 'faq.edit'] },
        component: () => import('@/views/pages/faqs/form')
    },
    {
        path: '/faq/view',
        name: 'FAQFormView',
        meta: { authRequired: true, roleDefault: '', role: ['faq.view'] },
        component: () => import('@/views/pages/faqs/view')
    }
];
