import { authHeader } from '@/helpers/fakebackend/auth-header';

export const employeeService = {
    registerEmployeePartTime,
    registerEmployee,
    listEmployeePartTime,
    listEmployee,
    detailEmployeePartTime,
    detailEmployee,
    deleteEmployee,
    deleteEmployeePartTime,
    updateEmployeePartTime,
    updateEmployee
};

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    window.$cookies.remove('token');
}

function registerEmployeePartTime(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/part-time-employees`, requestOptions).then(handleResponse);
}

function detailEmployeePartTime(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/part-time-employees/` + data, requestOptions).then(handleResponse);
}

function detailEmployee(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/employees/` + data, requestOptions).then(handleResponse);
}

function updateEmployeePartTime(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/part-time-employees/` + data.id, requestOptions).then(handleResponse);
}

function listEmployeePartTime(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/part-time-employees?` + query, requestOptions).then(handleResponse);
}

function listEmployee(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/employees?` + query, requestOptions).then(handleResponse);
}

function deleteEmployee(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/employees/` + data.id, requestOptions).then(handleResponse);
}

function deleteEmployeePartTime(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/part-time-employees/` + data.id, requestOptions).then(handleResponse);
}

function registerEmployee(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/employees`, requestOptions).then(handleResponse);
}

function updateEmployee(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/employees/` + data.id, requestOptions).then(handleResponse);
}

function refresh() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/auth/refresh`, requestOptions).then((response) => {
        return response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (data.code == 200 && data.data) {
                window.$cookies.set('token', data.data.access_token);
                location.reload(true);
            } else {
                logout();
                location.reload(true);
            }
        });
    });
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                if (data.message !== 'token.expired') {
                    // auto logout if 401 response returned from api
                    logout();
                    location.reload(true);
                } else {
                    refresh();
                    // location.reload(true);
                }
            }
            if (response.status === 422) {
                return data;
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
