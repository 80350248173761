import { authHeader } from '@/helpers/fakebackend/auth-header';

export const agencyService = {
    registerAgency,
    registerAgencySupport,
    listAgency,
    listAgencySupport,
    detailAgency,
    detailAgencySupport,
    deleteAgency,
    deleteAgencySupport,
    updateAgency,
    updateAgencySupport
};

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    window.$cookies.remove('token');
}

function registerAgency(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/agencies`, requestOptions).then(handleResponse);
}
function registerAgencySupport(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/agencies/${data.agency_id}/person-in-charges`, requestOptions).then(handleResponse);
}

function detailAgency(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/agencies/` + data, requestOptions).then(handleResponse);
}
function detailAgencySupport(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/agencies/${data.agency_id}/person-in-charges/${data.id}`, requestOptions).then(handleResponse);
}

function listAgency(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/agencies?` + query, requestOptions).then(handleResponse);
}

function listAgencySupport(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/agencies/` + id + `/person-in-charges`, requestOptions).then(handleResponse);
}

function deleteAgency(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/agencies/` + data.id, requestOptions).then(handleResponse);
}

function deleteAgencySupport(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/agencies/${data.agency_id}/person-in-charges/${data.id}`, requestOptions).then(handleResponse);
}

function updateAgency(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/agencies/` + data.id, requestOptions).then(handleResponse);
}

function updateAgencySupport(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/agencies/${data.agency_id}/person-in-charges/${data.id}`, requestOptions).then(handleResponse);
}
function refresh() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/auth/refresh`, requestOptions).then((response) => {
        return response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (data.code == 200 && data.data) {
                window.$cookies.set('token', data.data.access_token);
                location.reload(true);
            } else {
                logout();
                location.reload(true);
            }
        });
    });
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                if (data.message !== 'token.expired') {
                    // auto logout if 401 response returned from api
                    logout();
                    location.reload(true);
                } else {
                    refresh();
                    // location.reload(true);
                }
            }
            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
            return data;
        }
        return data;
    });
}
