import { authHeader } from '@/helpers/fakebackend/auth-header';

export const masterService = {
    register,
    list,
    deleteObj,
    update,
    listPermission,
    getPermission,
    registerPermission,
    findCompany,
    histories,
    listHoliday,
    registerHoliday,
    deleteMasterHoliday,
    updateHoliday,
    listHolidayYear,
    oneHolidayYear,
    registerHolidayYear,
    updateHolidayYear,
    deleteMasterHolidayYear,
    templateList,
    templateGetDetail,
    templateGetDraf,
    saveDraf,
    removeDraf,
    templateUpdate,
    templateCreate,
    templateDelete,
    listRule,
    documentPreview,
    rule2Update,
    rule2Create,
    rule2Delete,
    listMasterRule1,
    registerMasterRule1,
    deleteMasterRule,
    updateMasterRule,
    updateMasterRule1,
    deleteMasterRule1,
    deleteMasterRule2,
    createMasterRule1,
    getAllMasterRule,
    getAllDocuments,
    getDetailDocuments,
    detailMasterRule1,
    updateMasterRuleDetail1,
    createMasterRuleDetail1,
    getDefaultRule,
    getListScheduleFile,
    getDetailScheduleFile,
    createScheduleFile,
    updateScheduleFile,
    deleteScheduleFile,
    getDocumentFAQ,
    updateDocumentFAQ,
    deleteDocumentFAQ,
    postDocumentFAQ,
    postDocumentFAQPreview,
    partnerRequestSheet,
    getPartnerRequestSheet,
    updatePartnerRequestSheet,
    setDefaultCampaign,
    getMasterOrderScope
};

function logout() {
    // remove user from local storage to log user out
    // localStorage.removeItem('user');
    // window.$cookies.remove('token');
}

function register(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/master`, requestOptions).then(handleResponse);
}

function registerHoliday(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-holiday`, requestOptions).then(handleResponse);
}

function registerHolidayYear(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-year-end-schedule`, requestOptions).then(handleResponse);
}

function registerPermission(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/permissions`, requestOptions).then(handleResponse);
}

function saveDraf(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/prize-announcement-templates/${data.id}/draft`, requestOptions).then(handleResponse);
}

function templateUpdate(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/prize-announcement-templates/${data.id}`, requestOptions).then(handleResponse);
}

function templateCreate(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/prize-announcement-templates`, requestOptions).then(handleResponse);
}

function documentPreview(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/documents/preview`, requestOptions).then(handleResponse);
}

function removeDraf(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/prize-announcement-templates/${data.id}/draft`, requestOptions).then(handleResponse);
}

function templateDelete(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/prize-announcement-templates/${data.id}`, requestOptions).then(handleResponse);
}

function findCompany(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/companies?` + query, requestOptions).then(handleResponse);
}

function update(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/master/` + data.id, requestOptions).then(handleResponse);
}

function updateHoliday(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-holiday/` + data.id, requestOptions).then(handleResponse);
}

function updateHolidayYear(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-year-end-schedule/` + data.id, requestOptions).then(handleResponse);
}

function list(key) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master/` + key + ``, requestOptions).then(handleResponse);
}

function listHoliday() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-holiday`, requestOptions).then(handleResponse);
}

function listHolidayYear() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-year-end-schedule`, requestOptions).then(handleResponse);
}

function oneHolidayYear(year) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-year-end-schedule/${year}`, requestOptions).then(handleResponse);
}

function getPermission() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/permissions`, requestOptions).then(handleResponse);
}

function listPermission() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/permissions/list-permissions`, requestOptions).then(handleResponse);
}

function listRule(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-detail?${query}`, requestOptions).then(handleResponse);
}

function deleteObj(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master/` + data.id, requestOptions).then(handleResponse);
}

function deleteMasterHoliday(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-holiday/` + data.id, requestOptions).then(handleResponse);
}

function deleteMasterHolidayYear(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-year-end-schedule/` + data.id, requestOptions).then(handleResponse);
}

function histories(query) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/histories?` + query, requestOptions).then(handleResponse);
}

function templateList() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/prize-announcement-templates`, requestOptions).then(handleResponse);
}

function templateGetDraf(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/prize-announcement-templates/${data.id}/draft`, requestOptions).then(handleResponse);
}

function templateGetDetail(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/prize-announcement-templates/${data.id}`, requestOptions).then(handleResponse);
}

function refresh() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/auth/refresh`, requestOptions).then((response) => {
        return response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (data.code == 200 && data.data) {
                window.$cookies.set('token', data.data.access_token);
                location.reload(true);
            } else {
                logout();
                location.reload(true);
            }
        });
    });
}

//rule screen 2
function rule2Create(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-detail`, requestOptions).then(handleResponse);
}

function rule2Update(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-detail/${data.id}`, requestOptions).then(handleResponse);
}

function rule2Delete(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-detail/${data.id}`, requestOptions).then(handleResponse);
}

// rule1
function registerMasterRule1(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-condition-platform`, requestOptions).then(handleResponse);
}

function createMasterRule1(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data.data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-condition-platform/${data.rule_id}/rule`, requestOptions).then(handleResponse);
}

function listMasterRule1() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-condition`, requestOptions).then(handleResponse);
}

function updateMasterRule(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-condition-platform/${data.id}`, requestOptions).then(handleResponse);
}

function updateMasterRule1(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-condition-platform/${data.rule_id}/rule/${data.id}`, requestOptions).then(handleResponse);
}

function updateMasterRuleDetail1(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-condition/${data.id}`, requestOptions).then(handleResponse);
}

function createMasterRuleDetail1(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-condition`, requestOptions).then(handleResponse);
}

function deleteMasterRule(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-detail/${data.id}`, requestOptions).then(handleResponse);
}

function deleteMasterRule1(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-condition/${data.id}`, requestOptions).then(handleResponse);
}

function detailMasterRule1(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-condition/${data.id}`, requestOptions).then(handleResponse);
}

function deleteMasterRule2(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-condition-platform/${data.id}`, requestOptions).then(handleResponse);
}

function getAllMasterRule() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-detail/get-all`, requestOptions).then(handleResponse);
}

function getAllDocuments() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/all/for-document-screen`, requestOptions).then(handleResponse);
}

function getDetailDocuments(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/${data.id}/for-document-screen`, requestOptions).then(handleResponse);
}

function getDefaultRule() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-rule-detail/get-default`, requestOptions).then(handleResponse);
}

function getListScheduleFile() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-schedule-file`, requestOptions).then(handleResponse);
}

function getDetailScheduleFile(code) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-schedule-file/${code}`, requestOptions).then(handleResponse);
}

function createScheduleFile(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-schedule-file`, requestOptions).then(handleResponse);
}

function updateScheduleFile(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-schedule-file/${data.id}`, requestOptions).then(handleResponse);
}

function deleteScheduleFile(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-schedule-file/${data.id}`, requestOptions).then(handleResponse);
}

function getDocumentFAQ(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/${data.id}/faq`, requestOptions).then(handleResponse);
}

function updateDocumentFAQ(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/${data.campaign_id}/faq/${data.id}`, requestOptions).then(handleResponse);
}

function deleteDocumentFAQ(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/${data.campaign_id}/faq/${data.id}`, requestOptions).then(handleResponse);
}

function postDocumentFAQ(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/documents/preview/faq`, requestOptions).then(handleResponse);
}

function postDocumentFAQPreview(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/documents/preview`, requestOptions).then(handleResponse);
}

function partnerRequestSheet(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/partner-request-sheet/campaign/${data.campaign_id}`, requestOptions).then(handleResponse);
}

function updatePartnerRequestSheet(data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/partner-request-sheet/campaign/${data.campaign_id}`, requestOptions).then(handleResponse);
}
function setDefaultCampaign(data) {
    const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_APIURL + `/campaigns/${data.id}/set-default`, requestOptions).then(handleResponse);
}

function getPartnerRequestSheet(data) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/partner-request-sheet/campaign/${data.campaign_id}`, requestOptions).then(handleResponse);
}

function getMasterOrderScope() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_APIURL + `/master-order-scope`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                if (data.message !== 'token.expired') {
                    // auto logout if 401 response returned from api
                    logout();
                    location.reload(true);
                } else {
                    refresh();
                }
            }
            if (response.status === 422) {
                return data;
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
