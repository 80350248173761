export const routerMaster = [
    {
        path: '/master/other',
        name: 'Master',
        meta: {
            authRequired: true,
            roleDefault: 'all',
            role: []
        },
        component: () => import('@/views/pages/master/index')
    },
    {
        path: '/master/other/form',
        name: 'MasterForm',
        meta: {
            authRequired: true,
            roleDefault: '',
            role: ['master.register']
        },
        component: () => import('@/views/pages/master/form')
    },
    {
        path: '/master/holidays',
        name: 'Holiday',
        meta: { authRequired: true, roleDefault: 'all', role: [] },
        component: () => import('@/views/pages/master/holiday.vue')
    },
    {
        path: '/master/holiday-year',
        name: 'Holidayyear',
        meta: { authRequired: true, roleDefault: 'all', role: [] },
        component: () => import('@/views/pages/master/holiday-year.vue')
    },
    {
        path: '/master/template',
        name: 'Template',
        meta: { authRequired: true, roleDefault: 'all', role: [] },
        component: () => import('@/views/pages/template')
    },
    {
        path: '/master/manual',
        name: 'Manual',
        meta: { authRequired: true, roleDefault: 'all', role: [] },
        component: () => import('@/views/pages/master/manual/main.vue')
    },
    {
        path: '/master/template/preview',
        name: 'TemplatePreview',
        meta: { authRequired: true, roleDefault: 'all', role: [] },
        component: () => import('@/views/pages/documents/preview.vue')
    },
    {
        path: '/master/rule',
        name: 'MasterRule',
        meta: { authRequired: true, roleDefault: 'all', role: [] },
        component: () => import('@/views/pages/master/rule.vue')
    },
    {
        path: '/master/condition-rule',
        name: 'MasterRule1',
        meta: { authRequired: true, roleDefault: 'all', role: ['master.register'] },
        component: () => import('@/views/pages/master/rule1.vue')
    },
    {
        path: '/master/condition-rule-edit',
        name: 'MasterRule1Edit',
        meta: { authRequired: true, roleDefault: 'all', role: ['master.register'] },
        component: () => import('@/views/pages/master/rule-edit.vue')
    },
    {
        path: '/master/schedule/list',
        name: 'MasterScheduleList',
        meta: { authRequired: true, roleDefault: 'all', role: ['master.register'] },
        component: () => import('@/views/pages/master/schedule/list/main.vue')
    },
    {
        path: '/master/schedule/add',
        name: 'MasterScheduleEdit',
        meta: { authRequired: true, roleDefault: 'all', role: ['master.register'] },
        component: () => import('@/views/pages/master/schedule/add/main.vue')
    }
];
