export const routerEmployee = [
    {
        path: '/employees',
        name: 'Employees',
        meta: { authRequired: true, roleDefault: '', role: ['employee.view'] },
        component: () => import('@/views/pages/employee')
    },
    {
        path: '/employee/form',
        name: 'EmployeeForm',
        meta: { authRequired: true, roleDefault: '', role: ['employee.register', 'employee.edit'] },
        component: () => import('@/views/pages/employee/form')
    },
    {
        path: '/employee/view',
        name: 'EmployeeView',
        meta: { authRequired: true, roleDefault: '', role: ['employee.view'] },
        component: () => import('@/views/pages/employee/view')
    }
];
