import { employeeService } from '@/helpers/fakebackend/employee.service';

export const getters = {
    dataList(state) {
        console.log('getter', state);
        return state.dataList;
    }
};

export const actions = {
    // register the master
    registerEmployeePartTime({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return employeeService.registerEmployeePartTime(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error.message, { root: true });
            }
        );
    },
    // register the master
    updateEmployeePartTime({ dispatch, commit }, data) {
        commit('updateRequest', data);
        return employeeService.updateEmployeePartTime(data).then(
            (data) => {
                dispatch('notification/success', 'Update successful', { root: true });
                return data;
            },
            (error) => {
                commit('updateFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // list the master
    listEmployeePartTime({ dispatch, commit }, query) {
        commit('listRequest', []);
        return employeeService.listEmployeePartTime(query).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }, // list the master
    listEmployee({ dispatch, commit }, query) {
        commit('listRequest', []);
        return employeeService.listEmployee(query).then(
            (data) => {
                return data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // list the master
    detailEmployeePartTime({ dispatch, commit }, data) {
        commit('listRequest', []);
        return employeeService.detailEmployeePartTime(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // list the master
    detailEmployee({ dispatch, commit }, data) {
        commit('listRequest', []);
        return employeeService.detailEmployee(data).then(
            (data) => {
                commit('listSuccess', data.data);
                return data.data;
            },
            (error) => {
                commit('listFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // list the master
    deleteEmployee({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return employeeService.deleteEmployee(data).then(
            (data) => {
                // console.log(data.data)
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // list the master
    deleteEmployeePartTime({ dispatch, commit }, data) {
        commit('deleteRequest', data);
        return employeeService.deleteEmployeePartTime(data).then(
            (data) => {
                // console.log(data.data)
                commit('deleteSuccess', data.data);
                return data;
            },
            (error) => {
                commit('deleteFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },

    // register the master
    registerEmployee({ dispatch, commit }, data) {
        commit('registerRequest', data);
        return employeeService.registerEmployee(data).then(
            (data) => {
                dispatch('notification/success', 'Registration successful', { root: true });
                return data;
            },
            (error) => {
                commit('registerFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    },
    // register the master
    updateEmployee({ dispatch, commit }, data) {
        commit('updateRequest', data);
        return employeeService.updateEmployee(data).then(
            (data) => {
                dispatch('notification/success', 'Update successful', { root: true });
                return data;
            },
            (error) => {
                commit('updateFailure', error);
                dispatch('notification/error', error, { root: true });
            }
        );
    }
};

export const mutations = {
    registerRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    registerSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    registerFailure(state) {
        state.status = {};
        state.data = null;
    },
    listRequest(state, data) {
        state.status = false;
        state.dataList = data;
    },
    listSuccess(state, data) {
        state.status = true;
        // console.log('state State', state)
        state.dataList = data;
        //console.log('state State', state)
    },
    listFailure(state) {
        state.status = {};
        state.dataList = [];
    },
    deleteRequest(state, data) {
        state.status = false;
        state.data = data;
    },
    deleteSuccess(state, data) {
        state.status = true;
        state.data = data;
    },
    deleteFailure(state) {
        state.status = {};
        state.data = null;
    }
};
